<!DOCTYPE html>
<html>
	<head lang="en">
		<meta charset="UTF-8" />
		<title>Trea - Condominios</title>
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<link
			href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
			rel="stylesheet"
			integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
			crossorigin="anonymous"
		/>
		<script
			src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
			integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
			crossorigin="anonymous"
		></script>
		<meta name="theme-color" content="#e85e14" />
		<style type="text/css">
			:root {
				background-color: #e814e1;
			}
		</style>
	</head>

	<body>
		<div class="container d-flex justify-content-center align-items-center">
			<div class="row border rounded-5 p-3 shadow box-area main-container">
				<!-- ******** Left Box ******** -->
				<div
					class="featured-image col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box left-img-container"
				></div>

				<!-- ******** Right Box ******** -->
				<div class="col-md-6 right-box">
					<div class="row align-items-center">
						<div class="header-text mb-4 right-box-head">
							<img class="right-box-logo" src="../../../assets/treaLogo.png" alt="Trea Logo" />
							<h1 class="text-center mb-4">Condominios</h1>
						</div>

						<form [formGroup]="recoveryPasswordFormControl" (ngSubmit)="onSubmit()">
							<!-- *** Email Input *** -->
							<div class="mb-1 form-group row mt-4">
								<mat-form-field appearance="outline">
									<input
										[class.is-invalid]="invalidEmail"
										autocomplete="off"
										matInput
										formControlName="email"
										placeholder="{{ placeholders.email }}"
									/>
									<mat-error *ngIf="invalidEmail">You must enter a valid email address.</mat-error>
								</mat-form-field>
							</div>

							<!-- *** Forgot Password *** -->
							<div class="input-group mb-2 d-flex justify-content-between">
								<div class="form-check"></div>
								<div>
									<small> </small>
								</div>
							</div>

							<!-- *** Button Login *** -->
							<div class="input-group mt-4 mb-4">
								<button class="button btn-lg w-100 fs-6">
									{{ 'login.recoveryPassword' | translate }}
								</button>
							</div>
						</form>

						<div class="right-box-footer">
							<!-- *** Privacy Politics *** -->
							<div class="input-group mb-2 mt-2 d-flex justify-content-center align-items-center">
								<small class="text-center privacy-politics"> </small>
							</div>

							<!-- *** Environment Deployed *** -->
							<div
								*ngIf="isDevEnvironment"
								class="input-group mb-2 mt-4 d-flex justify-content-center align-items-center"
							>
								<small class="text-center environment-deployed">
									{{ 'login.environment' | translate }}
									<a href="https://thumbs.dreamstime.com/b/computer-repair-concept-3382437.jpg">DEVELOP 👨🏽‍💻</a>
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</body>
</html>
