<div class="main">
	<mat-card class="card">
		<div class="header">
			<a class="back" mat-button (click)="goBack()">
				<mat-icon>keyboard_backspace</mat-icon>
			</a>
			<img class="img" src="../../../assets/Group 86.svg" />
			<span class="label1">{{ 'change.change' | translate }}</span>
			<span class="label2">{{ 'change.password' | translate }}</span>
		</div>
		<mat-card-content>
			<div class="card-form-container">
				<form class="card-form" [formGroup]="changeFormControl">
					<mat-form-field class="formField">
						<input
							matInput
							[type]="hideNewPassword ? 'password' : 'text'"
							placeholder="{{ 'change.newPassword' | translate }}"
							formControlName="newPassword"
							[errorStateMatcher]="matcher"
						/>
						<button
							type="button"
							mat-icon-button
							matSuffix
							(click)="hideNewPassword = !hideNewPassword"
							[attr.aria-label]="'Hide new password'"
							[attr.aria-pressed]="hideNewPassword"
						>
							<mat-icon>{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
						</button>
						<mat-error *ngIf="changeFormControl.controls.newPassword.hasError('required')">
							{{ 'change.error.password' | translate }}
						</mat-error>
						<mat-error *ngIf="changeFormControl.controls.newPassword.hasError('pattern')">
							{{ 'change.error.invalidPassword' | translate }}
						</mat-error>
					</mat-form-field>

					<mat-form-field class="formField">
						<input
							matInput
							ngValidateEqual="newPassword"
							[type]="hidePassword ? 'password' : 'text'"
							placeholder="{{ 'change.confirmNewPassword' | translate }}"
							formControlName="confirmNewPassword"
							[errorStateMatcher]="matcher"
						/>
						<button
							type="button"
							mat-icon-button
							matSuffix
							(click)="hidePassword = !hidePassword"
							[attr.aria-label]="'Hide password'"
							[attr.aria-pressed]="hidePassword"
						>
							<mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
						</button>
						<mat-error *ngIf="changeFormControl.controls.confirmNewPassword.hasError('required')">
							{{ 'change.error.password' | translate }}
						</mat-error>
						<mat-error *ngIf="changeFormControl.controls.confirmNewPassword.hasError('pattern')">
							{{ 'change.error.invalidPassword' | translate }}
						</mat-error>
						<mat-error *ngIf="changeFormControl.controls.confirmNewPassword.hasError('notEqual')">
							<strong>{{ 'change.error.samePassword' | translate }}</strong>
						</mat-error>
					</mat-form-field>
				</form>
			</div>
		</mat-card-content>
		<mat-card-actions class="actions">
			<div class="change-div">
				<button (click)="onSubmit()" [disabled]="!changeFormControl.valid" mat-stroked-button class="change">
					<div>{{ 'change.change' | translate }}</div>
				</button>
			</div>
		</mat-card-actions>
	</mat-card>
</div>
<ngx-spinner></ngx-spinner>
