import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CARD_ID, FAKE_PERMITS, PERMITS, TAG, USER } from '../shared/ec-api/ec-api.module';
import { CarIdEntity } from '../shared/models/car-id-model';
import { TagEntity } from '../shared/models/tag-model';
import { UserEntity } from '../shared/models/user-model.module';
import { UserRoleEntity } from '../shared/models/user-role.module';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public pageIndex: number = 0;
	public pageRelatedIndex: number = 0;
	public page: number = 1;
	constructor(private http: HttpClient) {}
	public tags: any = {};
	public cardIds: any = {};
	httpOptions = {
		headers: new HttpHeaders({
			Authorization: '5TyX0s9ktFAvBFFMWWrxI45qGT2O1fnZ',
		}),
	};

	getUserList = (
		userUuid?: string,
		pageSize?: number,
		index?: number,
		status?: string,
		search?: string,
		space?: string,
		role?: string,
		superAdmin?: Boolean,
		exports?: boolean
	) => {
		return this.http.get<UserEntity>(
			`${USER}/?userUuid=${userUuid}&status=${status}&offset=${index}&limit=${pageSize}&search=${encodeURIComponent(
				search
			)}&space=${space}&role=${role}&superAdmin=${superAdmin}&export=${exports}`
		);
	};
	getUserListDefaulter = (userUuid?: string, pageSize?: number, index?: number, defaulter?: string | boolean) => {
		return this.http.get<UserEntity>(
			`${USER}/defaulter/?userUuid=${userUuid}&defaulter=${defaulter}&offset=${index}&limit=${pageSize}`
		);
	};
	getUserListWithFilters = (
		roleUuid?: string,
		spaceUuid?: string,
		pageSize?: number,
		index?: number,
		status?: string
	) => {
		return this.http.get<UserEntity>(
			`${USER}/filters/?roleUuid=${roleUuid}&spaceUuid=${spaceUuid}&status=${status}&offset=${index}&limit=${pageSize}`
		);
	};
	getUserByUuid = (uuid: string) => {
		return this.http.get<any>(`${USER}/${uuid}`);
	};

	updateUser = (user: FormData) => {
		return this.http.put<any>(`${USER}`, user);
	};
	updateUserProfile = (user: FormData) => {
		return this.http.put<any>(`${USER}/profile`, user);
	};
	registerUser = (user: FormData) => {
		return this.http.post<any>(`${USER}`, user);
	};
	deleteUser = (uuid: string) => {
		return this.http.delete<any>(`${USER}/${uuid}`);
	};

	getRoleList = () => {
		return this.http.get<UserRoleEntity>(`${USER}/roles/?status=ACTIVE&offset=0&limit=100`);
	};

	public getUserSpacesAndSpots = (data: UserEntity) => {
		return this.http.post<UserEntity>(`${USER}/spaces-spots`, data);
	};

	updateStatus = (user: UserEntity) => {
		return this.http.put<any>(`${USER}/update-status`, user);
	};

	updateStatusDefaulter = (user: UserEntity) => {
		return this.http.put<any>(`${USER}/update-status-defaulter`, user);
	};

	getUserListBySpot = (uuid?: string) => {
		return this.http.get<any>(`${USER}/spot/${uuid}`);
	};

	// Tags //
	getUserTagList = (userUuid?: string) => {
		return this.http.get<UserEntity>(`${TAG}/?uuid=${userUuid}`);
	};

	getCardIdList = (userUuid?: string) => {
		return this.http.get<UserEntity>(`${CARD_ID}/?uuid=${userUuid}`);
	};

	registerTag = (tag: TagEntity) => {
		return this.http.post<any>(`${TAG}`, tag);
	};

	registerCarId = (code: CarIdEntity) => {
		return this.http.post<any>(`${CARD_ID}`, code);
	};

	updateTagStatus = (tag: TagEntity) => {
		return this.http.put<any>(`${TAG}/update-status`, tag);
	};
	deleteTag = (uuid: string) => {
		return this.http.delete<any>(`${TAG}/${uuid}`);
	};

	deleteCarId = (uuid: string) => {
		return this.http.delete<any>(`${CARD_ID}/${uuid}`);
	};
	updateCarIdStatus = (carId: CarIdEntity) => {
		return this.http.put<any>(`${CARD_ID}/update-status`, carId);
	};

	///Permits///

	// OLD
	getPermitsByUser = (uuid: string) => {
		return this.http.get<any>(`${PERMITS}/${uuid}`);
	};

	// NEW
	getPermitsByUser2 = (uuid: string) => {
		return this.http.get<any>(`${FAKE_PERMITS}`, this.httpOptions);
	};

	safeTokenUser = (data: any) => {
		return this.http.put<any>(`${PERMITS}/safe-token`, data);
	};

	getAllPermits = () => {
		return this.http.get<any>(`${PERMITS}/`);
	};

	getPermitsBySpace = (uuid: string) => {
		return this.http.get<any>(`${PERMITS}/space/${uuid}`);
	};
	setPermits = (permits: any) => {
		return this.http.put<any>(`${PERMITS}/`, permits);
	};
	getUserByid = (id: string, user: string) => {
		return this.http.get<any>(`${USER}/find-id/${id}/${user}`);
	};
	getSpotByRol = (name: string) => {
		return this.http.get<any>(`${USER}/rol/${name}`);
	};
	getUserByIdAndSpace = (id: string, space: string) => {
		return this.http.get<any>(`${USER}/search-id/${id}/${space}`);
	};
	getUserByEmail = (email: string) => {
		return this.http.get<any>(`${USER}/search-email/${email}`);
	};
	getUserBySpaceList = (uuid: string, pageSize?: number, index?: number, status?: string, search?: string) => {
		return this.http.get<UserEntity>(
			`${USER}/user-space/${uuid}/?status=${status}&offset=${index}&limit=${pageSize}&search=${search}`
		);
	};
}
