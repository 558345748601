<div class="show-web" id="show-web">
  <div class="main">
    <mat-toolbar class="toolbar align-right">
      <div class="row">
        <button class="endButtons" mat-button (click)="onUserProfile()">
          <div class="welcome">
            <span class="profile">{{'dashboard.welcome' | translate}} {{ this.user.name }} {{ this.user.lastName }}</span>
          </div>
        </button>

        <button class="endButtons" mat-button matTooltip="{{'dashboard.logout' | translate}}" (click)="onExit()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <mat-sidenav-container [hasBackdrop]="false" class="navContainer">
      <mat-sidenav class="mat-elevation-z8" mode="side" opened="true" #snav>
        <mat-list class="dashboard-side-bar-list">
          <div>
            <button class="toggle" mat-button color="primary" (click)="navIsOpen()" (click)="snav.toggle(open)"
              matTooltip="Menu" matTooltipPosition="after">
              <mat-icon class="sidenavMenuButton">{{
                open ? "keyboard_arrow_left" : "keyboard_arrow_right"
                }}</mat-icon>
            </button>
          </div>
          <div *ngIf="showImage" class="center border-logo">
            <img class="logo-nav" src="../../assets/treaLogo.png" />
          </div>
          <div *ngIf="verifyRoles('user-list')" class="icons-nav">
            <button (click)="onUser('userMenu')" mat-button class="sidenavButton" color="primary"
             matTooltip="{{'dashboard.users' | translate}}"
              matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="user-icon"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    userMenu === true,
                  'navigation-item-label color-text': userMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.users' | translate}}</span></span>
            </button>
          </div>

          <div *ngIf="verifyRoles('spaces-list')" class="icons-nav">
            <button [ngClass]="{ sidenavButton: open === false }" color="primary" (click)="onSpaces('spaceMenu')"
              mat-button class="sidenavButton" color="primary" 
              matTooltip="{{'dashboard.condominiums' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="condominium"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    spaceMenu === true,
                  'navigation-item-label color-text': spaceMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.condominiums' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('reservations-list')" class="icons-nav">
            <button [ngClass]="{ sidenavButton: open === false }" color="primary"
              (click)="onReservations('reservationMenu')" mat-button class="sidenavButton" color="primary"
              matTooltip="{{'dashboard.booking' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="calendar"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    reservationMenu === true,
                  'navigation-item-label color-text': reservationMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.booking' | translate}}</span></span>
            </button>
          </div>

          <div *ngIf="verifyRoles('invitations-list')" class="icons-nav">
            <button color="primary" (click)="onAuthorizations('invitationsMenu')" mat-button class="sidenavButton"
              color="primary" matTooltip="{{'dashboard.invitations' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="card"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected': invitationsMenu === true,
                  'navigation-item-label color-text': invitationsMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.invitations' | translate}}</span></span>
            </button>
          </div>

          <div class="icons-nav" *ngIf="verifyRoles('security-reservations-list')">
            <button color="primary" (click)="onReservationsOfficer('reservationOfficerMenu')" mat-button
              class="sidenavButton" color="primary" matTooltip="{{'dashboard.booking' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="calendar"></mat-icon><span [ngClass]="{
                    'navigation-item-label color-text-selected':
                    reservationOfficerMenu === true,
                    'navigation-item-label color-text':  reservationOfficerMenu === false
                  }"><span *ngIf="hideTitle">{{'dashboard.booking' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('security-invitations-list')" class="icons-nav">
            <button color="primary" (click)="onAuthorizationsOfficer('invitationsMenu')" mat-button
              class="sidenavButton" color="primary" matTooltip="{{'dashboard.invitations' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="card"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected': invitationsMenu === true,
                  'navigation-item-label color-text': invitationsMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.invitations' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('administrations-list')" class="icons-nav">
            <button color="primary" (click)="onAdministrations('administrationsMenu')" mat-button class="sidenavButton"
              color="primary" matTooltip="{{'dashboard.administrations' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg administration-icon" svgIcon="shield"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    administrationsMenu === true,
                  'navigation-item-label color-text':
                    administrationsMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.administrations' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('request-list')" class="icons-nav">
            <button (click)="onRequest('requestMenu')" mat-button class="sidenavButton" color="primary"
              matTooltip="{{'dashboard.requests' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="request"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    requestMenu === true,
                  'navigation-item-label color-text': requestMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.requests' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('releases-list')" class="icons-nav">
            <button (click)="onReleases('releasesMenu')" mat-button class="sidenavButton" color="primary"
              matTooltip="{{'dashboard.releases' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="notification"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    releasesMenu === true,
                  'navigation-item-label color-text': releasesMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.releases' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('terminals-list')" class="icons-nav">
            <button (click)="onTerminals('terminalsMenu')" mat-button class="sidenavButton" color="primary"
              matTooltip="{{'dashboard.terminals' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="terminal"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    terminalsMenu === true,
                  'navigation-item-label color-text': terminalsMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.terminals' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('logs-list')" class="icons-nav">
            <button [ngClass]="{ sidenavButton: open === false }" color="primary" (click)="onLogs('logsMenu')"
              mat-button class="sidenavButton" color="primary" matTooltip="{{'dashboard.liveEvents' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="notification_outline"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected': logsMenu === true,
                  'navigation-item-label color-text': logsMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.liveEvents' | translate}}</span></span>
            </button>
          </div>
          <div *ngIf="verifyRoles('record-list')" class="icons-nav">
            <button (click)="onRecord('recordMenu')" mat-button class="sidenavButton" color="primary"
              matTooltip="{{'dashboard.matTooltip.history' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" svgIcon="record"></mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                    recordMenu === true,
                  'navigation-item-label color-text': recordMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.history' | translate}}</span></span>
            </button>
          </div>
          <div class="icons-nav">
            <button (click)="onReports('reportsMenu')" mat-button class="sidenavButton" color="primary"
              matTooltip="{{'dashboard.reports' | translate}}" matTooltipPosition="after">
              <mat-icon class="sidenavIconSvg" >show_chart</mat-icon><span [ngClass]="{
                  'navigation-item-label color-text-selected':
                  reportsMenu === true,
                  'navigation-item-label color-text': reportsMenu === false
                }"><span *ngIf="hideTitle">{{'dashboard.reports' | translate}}</span></span>
            </button>
          </div>
        </mat-list>
      </mat-sidenav>

      <mat-sidenav-content [ngClass]="{
          closedSideNav: open === false,
          openedSideNav: open === true
        }">
        <router-outlet name="menu" *ngIf="showRouter"></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>