import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'e-commerce-workspace-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  constructor() { }
	viewPolitics(){
		//this.router.navigate(['/policy']);
		window.open('https://drive.google.com/drive/folders/1D8ak9jvqUDjg0IbEhchgNAGyIZtz1Mcu?usp=sharing', '_blank');
	}
  ngOnInit(): void {
  }

}
