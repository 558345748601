import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from '../../auth/auth.service';
import { EcErrorStateMatcher } from '../../shared/error-state-matcher/error-state-matcher';
import { LoadingIndicatorService } from '../../shared/loading-indicator/loading-indicator.service';
import { PictureEntity } from '../../shared/models/picture.module';
import { UserEntity } from '../../shared/models/user-model.module';
import { UserService } from '../user.service';

@Component({
	selector: 'user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
	public selected;
	public languageUser;
  checked ;
  disabled ;
	userFormControl = new FormGroup({
		name: new FormControl(null, [Validators.required]),
		lastName: new FormControl(null, [Validators.required]),
		surName: new FormControl(null, [Validators.required]),
		phone: new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^\d+$/),
		]),
		mobile: new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^\d+$/),
		]),
		email: new FormControl(null, [Validators.required, Validators.email]),
		language: new FormControl(null),
		password: new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
		newPassword: new FormControl(null, [
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
		confirmNewPassword: new FormControl(null, [
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
	});
	userFormControl2 = new FormGroup({
		newPassword: new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
		confirmNewPassword: new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.minLength(8),
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
	});

	isLinear = false;
	firstFormGroup: FormGroup;
	secondFormGroup: FormGroup;
	public hideConfirmPassword = true;
	public hidePassword = true;
	public hideNewPassword = true;
	public changePassword = false;
	public edit = false;
	public visible = true;
	matcher = new EcErrorStateMatcher();
	public user: UserEntity;
	public userPicture: PictureEntity;
	public profilePhoto = '';

	@ViewChild('stepper') stepper: MatStepper;
	public langs;
	constructor(
		private router: Router,
		private _formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA)
		public data: { user: UserEntity; editing: boolean },
		public dialogRef: MatDialogRef<UserProfileComponent>,
		private loadingIndicator: LoadingIndicatorService,
		private userService: UserService,
		private authService: AuthService,
		private toaster: Toaster,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private translate: TranslateService
	) {
		this.matIconRegistry.addSvgIcon(
			`user`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/user.svg`)
		);
		this.matIconRegistry.addSvgIcon(
			`cancel`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/close.svg`)
		);
		this.langs=this.translate.getLangs();
	}

	ngOnInit() {
		const user=JSON.parse(localStorage.getItem('user'));
		this.getLanguage(user.id);
		this.firstFormGroup = this._formBuilder.group({
			firstCtrl: ['', Validators.required],
		});
		this.secondFormGroup = this._formBuilder.group({
			secondCtrl: ['', Validators.required],
		});
		this.changePosition();
		const userStorage = localStorage.getItem('user');
		if (userStorage) {
			this.user = JSON.parse(userStorage);
		}
		this.getForm(this.user);
	}
	changePosition() {
		this.dialogRef.updatePosition({ top: '62px', right: '50px' });
	}
	getForm = (user: UserEntity) => {
		if (user) {
			this.userFormControl.controls.name.setValue(user.name);
			this.userFormControl.controls.lastName.setValue(user.lastName);
			this.userFormControl.controls.surName.setValue(user.surName);
			this.userFormControl.controls.phone.setValue(user.phone);
			this.userFormControl.controls.mobile.setValue(user.mobile);
			this.userFormControl.controls.email.setValue(user.email);
			this.userFormControl.controls.password.setValue(user.password);
			this.profilePhoto = user.picture.url;
			this.userPicture = user.picture;
		}
	};

	onFileSelected(event) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (event: ProgressEvent<FileReader>) => {
			file.url = event.target.result;
		};
		reader.readAsDataURL(file);
		this.userPicture = file;
		this.profilePhoto = file.url;
	}

	onSubmit() {
		this.loadingIndicator.show();
		const formData: FormData = new FormData();
		formData.append('photo', this.userPicture);

		const user: UserEntity = {
			name: this.userFormControl.controls.name.value,
			lastName: this.userFormControl.controls.lastName.value,
			surName: this.userFormControl.controls.surName.value,
			email: this.userFormControl.controls.email.value,
			password: this.userFormControl.controls.password.value,
			status: 'ACTIVE',
			phone: this.userFormControl.controls.phone.value,
			mobile: this.userFormControl.controls.mobile.value,
			//createdBy : this.authService.user.name,
		};

		if (this.user) {
			user.uuid = this.user.uuid;
			user.picture = this.userPicture;
			formData.append('data', JSON.stringify(user));
			this.userService.updateUserProfile(formData).subscribe(this.userUpdateSuccess, this.userUpdateFailure);
		}
	}

	userUpdateSuccess = (res: any) => {
		this.	updateLanguageUser();
		this.toaster.open({
			text: this.translate.instant('userProfile.toasters.userUpdateSuccess.message'),
			caption: this.translate.instant('userProfile.toasters.userUpdateSuccess.title'),
			type: 'success',
		});
		this.userService.getUserByUuid(this.user.uuid).subscribe(this.getUserSuccess);
		this.loadingIndicator.hide();
		this.onClose();
	};
	getUserSuccess = (res: any) => {
		this.authService.user = res.body;
	};
	userUpdateFailure = (res: any) => {
		this.toaster.open({
			text: this.translate.instant('userProfile.toasters.userUpdateFailure.message'),
			caption: this.translate.instant('userProfile.toasters.userUpdateFailure.title'),
			type: 'danger',
		});
		this.loadingIndicator.hide();
	};

	onConfirm = () => {
		if (this.userFormControl2.controls.confirmNewPassword.value === this.userFormControl2.controls.newPassword.value) {
			this.changePassword = false;
			this.userFormControl.controls.password.setValue(this.userFormControl2.controls.confirmNewPassword.value);
			const user = this.user;
			user.password = this.userFormControl2.controls.confirmNewPassword.value;
			this.authService.changePass(user).subscribe(this.changePasswordSuccess, this.changePasswordFailure);
		}
	};

	changePasswordSuccess = (res: any) => {
		this.toaster.open({
			text: this.translate.instant('userProfile.toasters.changePasswordSuccess.message'),
			caption: this.translate.instant('userProfile.toasters.changePasswordSuccess.title'),
			type: 'success',
		});
		this.userFormControl2.controls.confirmNewPassword.setValue(null);
		this.userFormControl2.controls.newPassword.setValue(null);
	};

	changePasswordFailure = (res: any) => {
		this.toaster.open({
			text: this.translate.instant('userProfile.toasters.changePasswordFailure.message'),
			caption: this.translate.instant('userProfile.toasters.changePasswordFailure.title'),
			type: 'danger',
		});
	};
	onClose() {
		this.dialogRef.close();
	}
	move(index: number) {
		this.stepper.selectedIndex = index;
	}
	validateNumber(obj) {
		const value = obj.target.value.replace(/\D/g, '');
		this.userFormControl.controls.mobile.setValue(value);
	}
	validateNumberTel(obj) {
		const value = obj.target.value.replace(/\D/g, '');
		this.userFormControl.controls.phone.setValue(value);
	};
	

  getLanguage(id){
		if(JSON.parse(localStorage.getItem('language'))){
			this.userFormControl.controls.language.setValue(JSON.parse(localStorage.getItem('language')));
		}else{
			this.authService.getLanguage(id).subscribe(
				(res) => {
					if(res.body.language){
						localStorage.setItem('language', JSON.stringify(res.body.language));
						this.userFormControl.controls.language.setValue(res.body.language);
					}
				},
				(error) => {
					this.toaster.open({
						text:  this.translate.instant('general.ErrorLoadingLanguage'),
						caption: 'Error ',
						type: 'danger',
					});
				}
			);
		}
	
	};
	updateLanguageUser(){
    const formData: FormData = new FormData();
		const user=JSON.parse(localStorage.getItem('user'));
    const language={
      userId:user.id,
      language:this.userFormControl.controls.language.value 
    };
    formData.append('data', JSON.stringify(language));

    this.authService.updateLanguage(formData)
		.subscribe(
			(res) => {
				localStorage.setItem('language', JSON.stringify(res.body.language));
				this.translate.setDefaultLang(this.userFormControl.controls.language.value);
				this.translate.addLangs(['english','español']);
			},
			(error) => {
		
			}
		);
  }
}
