import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangeComponent } from './change/change.component';
import { PolicyComponent } from './policy/policy.component';
import { LoginComponent } from './login/login.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';

const routes: Routes = [
	// { path: 'v1', component: LoginComponent },
	{ path: '', component: LoginComponent },
	{ path: 'forgot-passwordv2', component: ForgotPasswordComponent },
	{ path: 'recovery-password', component: RecoveryPasswordComponent },
	{ path: 'change', component: ChangeComponent },
	{ path: 'policy', component: PolicyComponent },
];

@NgModule({
	declarations: [],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
