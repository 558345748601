export namespace NotificationEventsModel {
  export enum NOTIFICATION_EVENT_TOPICS {
    EVENT_LOG = 'EVENT_LOG'
  }
  
  export enum NOTIFICATION_ACCESS_TYPE {
    QR = 'QR',
    ID = 'ID'
  }

  export interface NotificationEventEntity {
    status: String;
    userName: String;
    allowedName: String;
    allowed_id: String;
    date: Date;
    time: String;
    invitedBy: String;
    terminal:String;
    type: String;
    code: String;
    access: NOTIFICATION_ACCESS_TYPE
  }
}

