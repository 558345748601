import { Component } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from './auth/auth.service';
import { FirebaseService } from './shared/firebase/firebase.service';

@Component({
	selector: 'trea-workspace-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	constructor(
		private firebaseService: FirebaseService,
		private toaster: Toaster,
		private afMessaging: AngularFireMessaging,
		private translate:TranslateService,
		private authService: AuthService,
	) {
		this.requestPermission();
		this.listen();
		this.getLanguage();
	}

	requestPermission() {
		this.afMessaging.requestPermission.subscribe({
			next: (token) => {
				console.log('Permission granted! Save to the server!', token);
				this.afMessaging.getToken.subscribe({
					next: (token) => {
						console.log('Permission granted! Save to the server!', token);
						localStorage.setItem('notificationToken', token);
					},
					error: (error) => {
						console.log('Permission granted! Save to the server!', error);
					},
				});
			},
			error: (error) => {
				console.error(error);
			},
		});
	};
	getLanguage(){
		if(JSON.parse(localStorage.getItem('language'))){

			this.translate.setDefaultLang(JSON.parse(localStorage.getItem('language')));
			this.translate.addLangs(['english','español']);

		}else{

			const user=JSON.parse(localStorage.getItem('user'));
			if(user){
				this.authService.getLanguage(user.id).subscribe(
					(res) => {
						if(res.body.language){
					
							localStorage.setItem('language', JSON.stringify(res.body.language));
							this.translate.setDefaultLang(res.body.language);
							this.translate.addLangs(['english','español']);
						}
					},
					(error) => {
						this.translate.setDefaultLang('español');
							this.translate.addLangs(['english','español']);
						this.toaster.open({
							text:  this.translate.instant('general.ErrorLoadingLanguage'),
							caption: 'Error ',
							type: 'danger',
						});
					}
				);
			}else{
				this.translate.setDefaultLang('español');
				this.translate.addLangs(['english','español']);
			};

		}
	};

	listen() {

		this.afMessaging.messages.subscribe((message) => {
			console.log('1', message);
			const jsonUser = localStorage.getItem('user');
			if (jsonUser) {
				const user = JSON.parse(jsonUser);
				if (user?.role && user?.role.length > 0 && user?.role[0].key !== 'SUPER_ADMIN') {
					const type = message?.data.status === 'DENY' ? 'warning' : 'info';
					let userID = message?.data.allowedId;
					if (!message?.data.allowedId) {
						userID = this.translate.instant('appComponent.unknown');
					}
					switch (message?.data.type) {
						case 'ENTRY': {
							const text = message?.data.status === 'DENY' 
							? this.translate.instant('appComponent.attemptedEntryOf') 
							: this.translate.instant('appComponent.entryOf');
							// validar si viene un ingreso con placa incorrecta
							if(message?.data.plate ==='true'){	
								this.toaster.open({
									text: `${text} ${message?.data.allowedName} ${this.translate.instant('appComponent.differentPlateRegistered')} ${message?.data.terminal}`,
									caption: this.translate.instant('appComponent.terminalAccess'),
									type: 'warning',
								});
							
							}else if(message?.data.notificationType==='APIN'){
								this.toaster.open({
									text: `${this.translate.instant('appComponent.entryOf')} ${message?.data.allowedName}, ${this.translate.instant('appComponent.codeInTerminal')} ${message?.data.terminal}`,
									caption: this.translate.instant('appComponent.terminalAccess'),
									type: type,
								});

							}else if(message?.data.notificationType==='APIND'|| message?.data.notificationType==='APIND_Alert'){
										if(message?.data.notificationType==='APIND_Alert'){
											this.toaster.open({
												text: `${this.translate.instant('appComponent.intrusiveCode')} ${message?.data.terminal}`,
												caption: this.translate.instant('appComponent.intrusionAttempt'),
												duration: 50000,
												type: 'danger',
											});
										}else{
											this.toaster.open({
												text: `${this.translate.instant('appComponent.attemptedEntryCode')} ${message?.data.terminal}`,
												caption: this.translate.instant('appComponent.terminalAccess'),
												type: type,
											});
										}
							}else{
								this.toaster.open({
									text: `${text}  ${message?.data.allowedName} ${this.translate.instant('appComponent.withIdentification')}  ${userID}, ${this.translate.instant('appComponent.inTerminalOf')} ${message?.data.terminal}`,
									caption: this.translate.instant('appComponent.terminalAccess'),
									type: type,
								});
							}
							
							break;
						}
						case 'EXIT': {
							const text = message?.data.status === 'DENY' ? this.translate.instant('appComponent.attemptedDepartureOf') : this.translate.instant('appComponent.departureOf');
							this.toaster.open({
								text: `${text}  ${message?.data.allowedName} ${this.translate.instant('appComponent.withIdentification')}  ${userID}, ${this.translate.instant('appComponent.inTerminalOf')} ${message?.data.terminal}`,
								caption: this.translate.instant('appComponent.terminalAccess'),
								type: type,
							});
							break;
						}
						case 'RESERVATION': {
							if (user?.role[0].key === 'ADMIN') {
								this.toaster.open({
									text: `${this.translate.instant('appComponent.reservationOnHoldOf')} ${message?.data.allowedName} ${this.translate.instant('appComponent.ofTheCondominium')} ${message?.data.space} ${this.translate.instant('appComponent.ofTheApartment')} ${message?.data.spot}, ${this.translate.instant('appComponent.forAmenityUse')} ${message?.data.commonArea} ${this.translate.instant('appComponent.theDay')} ${message?.data.date}`,
									caption: this.translate.instant('appComponent.reservationOnHold'),
									type: type,
								});
							}
							break;
						}
						case 'REQUEST': {
							if (user?.role[0].key === 'ADMIN') {
								this.toaster.open({
									text: `${this.translate.instant('appComponent.theTenant')} ${message?.data.allowedName} ${this.translate.instant('appComponent.ofTheCondominium')} ${message?.data.space} ${this.translate.instant('appComponent.ofTheApartment')} ${message?.data.spot}, ${this.translate.instant('appComponent.requestOfService')}`,
									caption: this.translate.instant('appComponent.requestOnHold'),
									type: type,
								});
							}
							break;
						}
						case 'PARKING-FULL': {
							this.toaster.open({
								text: `${this.translate.instant('appComponent.theUser')} ${message?.data.allowedName} ${this.translate.instant('appComponent.invitedFor')} ${message?.data.requestedBy} ${this.translate.instant('appComponent.ofTheApartment')} ${message?.data.spot}, ${this.translate.instant('appComponent.guestParkingWithoutSpaces')}`,
								caption: this.translate.instant('appComponent.fullParking'),
								type: type,
							});

							break;
						}
						case 'PARKING-FULL-SPOT': {
							this.toaster.open({
								text: `${this.translate.instant('appComponent.theUser')} ${message?.data.allowedName} ${this.translate.instant('appComponent.invitedFor')} ${message?.data.requestedBy} ${this.translate.instant('appComponent.ofTheApartment')} ${message?.data.spot}, ${this.translate.instant('appComponent.apartmentParkingWithoutSpaces')}`,
								caption: this.translate.instant('appComponent.fullParking'),
								type: type,
							});

							break;
						}
						case 'ALARM': {
							this.toaster.open({
								text: `${this.translate.instant('appComponent.haveAAlarm')} ${message?.data.code}, ${this.translate.instant('appComponent.withTheDetail')} ${message?.data.info} ${this.translate.instant('appComponent.inTerminal')} ${message?.data.terminal}`,
								caption: this.translate.instant('appComponent.alarmInTerminal'),
								type: type,
							});

							break;
						}
					}

					this.firebaseService.messageSubject$.next(message);
				}
			}
		});
		this.afMessaging
			.onBackgroundMessage((data) => {
				console.log('2', data);
			})
			.then((data) => {
				console.log('3', data);
			})
			.catch((data) => {
				console.log(data);
			});

		this.afMessaging
			.onMessage((data) => {
				console.log('4', data);
			})
			.then((data) => {
				console.log('5', data);
			})
			.catch((data) => {
				console.log(data);
			});
	}
}
