import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPrintModule } from 'ngx-print';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RecordModalComponent } from './record/record/record-modal/record-modal.component';
import { firebaseConfig } from './shared/firebase/firebase.configuration';
import { FirebaseService } from './shared/firebase/firebase.service';
import { NotificationEventService } from './shared/notifications';
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
	declarations: [AppComponent, DashboardComponent, RecordModalComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AuthRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MatSidenavModule,
		MatIconModule,
		MatToolbarModule,
		MatListModule,
		MatButtonModule,
		MatTooltipModule,
		MatExpansionModule,
		MatDialogModule,
		MatMenuModule,
		ToastNotificationsModule.forRoot({ position: 'top-right' }),
		FormsModule,
		ReactiveFormsModule,
		NgxMaterialTimepickerModule,
		NgxPrintModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireMessagingModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateHttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [NotificationEventService, FirebaseService],

	bootstrap: [AppComponent],
})
export class AppModule {}

export function TranslateHttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
