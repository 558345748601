import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  constructor(private spinner: NgxSpinnerService) {}

  public show = () => {
    this.spinner.show(undefined, {
      type: 'square-jelly-box',
      size: 'large',
      bdColor: 'rgba(255,255,255, .8)',
      color: '#000000',
      fullScreen: true,
    });
  };

  public hide = () => {
    this.spinner.hide();
  };
}
