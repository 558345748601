import { Injectable } from '@angular/core';
import { FB_MESSSAGING_VAPID_KEY, firebaseConfig } from './firebase.configuration';
import { initializeApp, FirebaseApp } from "firebase/app";
import { getToken, GetTokenOptions } from "firebase/messaging";
import { getMessaging, Messaging } from "firebase/messaging/sw";
import { from, Observable, Subject } from 'rxjs';

@Injectable()
export class FirebaseService {

  public admin: FirebaseApp = null;
  public messaging: Messaging = null;
  public messageSubject$ = new Subject();

  constructor() {}

  public initialize(){
    this.admin = initializeApp(firebaseConfig);
  }
  
  public generateToken(): Observable<String>{
    this.messaging = getMessaging(this.admin);

    const tokenOptions: GetTokenOptions = {
      vapidKey: FB_MESSSAGING_VAPID_KEY
    }
    return from(getToken(this.messaging, tokenOptions));
  }

}
