import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NOTIFICATIONS } from '../ec-api/ec-api.module';
import { NotificationEventsModel as Model } from './noitification-event.model';

@Injectable()
export class NotificationEventService {

  public currentToken: String = null;
  public subjectListener = new Subject();

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders(),
  };

  public subscribeEvent(topic: Model.NOTIFICATION_EVENT_TOPICS) {
    const subscription = { token: this.currentToken, topic };
    return this.http.post(`${NOTIFICATIONS}/subscribe`, subscription , this.httpOptions);
  };

  public unsubscribeEvent(topic: Model.NOTIFICATION_EVENT_TOPICS){
    const subscription = { token: this.currentToken, topic };
    return this.http.post(`${NOTIFICATIONS}/unsubscribe`, subscription, this.httpOptions);
  };

  public deleteToken = (uuid: string,token: string) => {
    return this.http.delete<any>(`${NOTIFICATIONS}/${uuid}/${token}`);
  };
  
}