import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingIndicatorService } from '../../../shared/loading-indicator/loading-indicator.service';
import { UrlExport } from '../../../shared/models/url-export';

@Component({
	selector: 'e-commerce-workspace-record-modal',
	templateUrl: './record-modal.component.html',
	styleUrls: ['./record-modal.component.scss'],
})
export class RecordModalComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: UrlExport,
		private dialogRef: MatDialogRef<RecordModalComponent>,
		private loadingIndicator: LoadingIndicatorService
	) {}

	closeDialog() {
		this.dialogRef.close();
	}

	stop() {
		this.loadingIndicator.hide();
		this.closeDialog();
	}

	ngOnInit(): void {}
}
