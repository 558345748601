import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { EcErrorStateMatcher } from '../../shared/error-state-matcher/error-state-matcher';
import { LoadingIndicatorService } from '../../shared/loading-indicator/loading-indicator.service';
import { UserEntity } from '../../shared/models/user-model.module';
import { RoleService } from '../../shared/role.service';
import { UserService } from '../../user/user.service';
import { AuthService } from '../auth.service';
@Component({
	selector: 'e-commerce-workspace-recovery-password',
	templateUrl: './recovery-password.component.html',
	styleUrls: ['./recovery-password.component.css'],
})
export class RecoveryPasswordComponent {
	recoveryPasswordFormControl = new FormGroup({
		email: new FormControl(null, [Validators.required, Validators.email]),
		password: new FormControl(null, [
			Validators.required,
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
	});
	public hidePassword = true;
	public visible = true;
	matcher = new EcErrorStateMatcher();
	public user: UserEntity;
	public isDevEnvironment: boolean = false;
	public currentDomain: string;
	public token: string;
	public currentPath: URL;

	constructor(
		private router: Router,
		private loadingIndicator: LoadingIndicatorService,
		private authService: AuthService,
		private userService: UserService,
		private toaster: Toaster,
		private roleService: RoleService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private translate: TranslateService
	) {
		this.matIconRegistry.addSvgIcon(
			`password`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/padlock.svg`)
		);
		this.matIconRegistry.addSvgIcon(
			`user`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/userS.svg`)
		);
	}

	ngOnInit(): void {
		localStorage.setItem('language', JSON.stringify('español'));
		this.isDevEnvironment = window.location.href.indexOf('https://dev') === 0;
	}

	placeholders = {
		email: this.translate.instant('login.emailPlaceholder'),
	};

	get invalidEmail() {
		return (
			this.recoveryPasswordFormControl.get('email')?.invalid && this.recoveryPasswordFormControl.get('email')?.touched
		);
	}

	onSubmit() {
		const user: UserEntity = {
			email: this.recoveryPasswordFormControl.controls.email.value,
		};
		this.authService.forgotPass(user).subscribe(this.resetSuccess, this.resetFailure);
	}

	resetSuccess = (res: any) => {
		this.toaster.open({
			text: this.translate.instant('forgotPassword.toasters.resetSuccess.message'),
			caption: this.translate.instant('forgotPassword.toasters.resetSuccess.title'),
			type: 'warning',
		});
		this.goBack();
	};
	resetFailure = (res: any) => {
		this.toaster.open({
			text: this.translate.instant('forgotPassword.toasters.resetFailure.message'),
			caption: this.translate.instant('forgotPassword.toasters.resetFailure.title'),
			type: 'danger',
		});
	};

	goBack() {
		this.router.navigate(['/auth']);
	}
}
