<div class="main">
  <mat-card class="card">
    		<div class="header">
			<img class="img1" src="../../../assets/TREA APLICACION-03.png" />

		
		</div>
  </mat-card>
  <div>
    <mat-card-content>
      <div class="formContainer">
          <div class="margin">
                    <h1> Política de Privacidad de Trea</h1>  
            <br>
            
            Fecha de entrada en vigor: 20 de junio del 2023 <br><br>
            Agradecemos su interés en nuestra aplicación. La protección de sus datos personales es una
            prioridad para nosotros. En esta política de privacidad, describiremos cómo recopilamos,
            utilizamos, divulgamos y protegemos su información personal en cumplimiento con el 
            Reglamento General de Protección de Datos de la Unión Europea (RGPD).<br><br>
            1. Información que recopilamos<br><br>
            1.1 Información personal que usted proporciona: Podemos recopilar y almacenar la información
            personal que usted proporciona al utilizar nuestra aplicación, como su nombre, dirección de
            correo electrónico, imágenes y cualquier otro dato que elija compartir con nosotros al
            registrarse o utilizar funciones específicas de la aplicación.<br>
            1.2 Información de la imagen: Nuestra aplicación permite a los usuarios cargar imágenes para
            diversos fines. Al cargar una imagen, recopilamos y almacenamos la información asociada
            con la misma, como el nombre de archivo, el formato de archivo y los metadatos (si están
            disponibles).<br>
            1.3 Reenvío de archivos multimedia. Si un usuario reenvía archivos multimedia en un mensaje,
            los almacenamos en nuestros servidores para garantizar una entrega más efectiva de reenvíos
            adicionales, y respuestas a sus solicitudes.<br>
           -   1.3.1. Tipo de información recopilada: Nuestra aplicación puede recopilar información de imagen de los usuarios, que incluye fotografías, imágenes capturadas con la cámara del dispositivo, avatares y cualquier otro contenido visual relacionado.<br>

            -   1.3.2.Propósito de la recopilación de información: La información de imagen de los usuarios se utiliza exclusivamente con el propósito de brindar la funcionalidad y los servicios solicitados por los usuarios a través de la aplicación.<br>
            
            -   1.3.3.Consentimiento del usuario: Antes de recopilar cualquier información de imagen de los usuarios, obtendremos el consentimiento explícito del usuario. Esto se realizará mediante una solicitud de permiso en la aplicación, explicando claramente el propósito de la recopilación y el uso de la información.<br>



            1.4 Enviar tu información a aquellos que eliges para comunicarte. Tú compartes tu información
            (incluidos mensajes) mientras usas nuestros Servicios y te comunicas por medio de ellos.<br>
            1.5 Información del dispositivo: También podemos recopilar información relacionada con su
            dispositivo, como el modelo, el sistema operativo, la versión de la aplicación y otros datos
            técnicos necesarios para garantizar el correcto funcionamiento de la aplicación.<br>
            1.6 A través de nuestros Productos puedes enviar mensajes, y hacer fotos, entre otras
            posibilidades. Llamamos “actividad” a todas las acciones que puedes realizar con nuestros
            Productos. Recogemos dicha actividad y la información que proporcionas, como los
            siguientes datos:<br>
            • Contenido que creas, solicitudes, reservas o invitaciones<br>
            • Contenido que proporcionas a través de nuestra función de cámara, de la
            configuración de tu galería.<br>
            • Mensajes que envías y recibes, incluido su contenido, conforme a la legislación
            aplicable<br><br>

            2. Uso de la información<br><br>

            2.1 Utilización de la información personal: Utilizamos la información personal recopilada para
            brindarle una experiencia personalizada y mejorar nuestros servicios. Podemos utilizar su
            información para comunicarnos con usted, responder a sus solicitudes, proporciona
            actualizaciones de la aplicación y enviarle información relacionada con nuestros productos y
            servicios, siempre que haya otorgado su consentimiento previo.<br>
            2.2 Información técnica y de uso: Utilizamos la información técnica y de uso para mejorar y
            personalizar su experiencia con la aplicación, así como para garantizar la seguridad de nuestros
            sistemas y prevenir el uso fraudulento de la aplicación.<br><br>
            3. Divulgación de la información<br><br>
            3.1 Proveedores de servicios: Podemos compartir su información personal con terceros
            proveedores de servicios que nos ayuden a proporcionar y mejorar la aplicación. Estos
            proveedores de servicios están sujetos a acuerdos de confidencialidad y solo pueden utilizar su
            información personal de acuerdo con nuestras instrucciones.<br>
            3.2 Cumplimiento legal: Podemos divulgar su información personal si así lo exige la ley o si
            creemos de buena fe que dicha divulgación es necesaria para cumplir con una obligación legal,
            proteger nuestros derechos o defenderse de reclamaciones legales.<br><br>
            4. Transferencia internacional de datos<br><br>
            Su información personal puede ser transferida y almacenada en países fuera de la Unión
            Europea.<br>
            En tales casos, nos aseguraremos de que se apliquen las salvaguardias adecuadas para
            proteger su información de acuerdo con las leyes de protección de datos aplicables.<br><br>
            5. Retención de datos<br><br>
            Mantenemos su información personal durante el tiempo que sea necesario para cumplir con los
            fines establecidos en esta política de privacidad, a menos que se requiera un período de retención
            más largo o permitido por la ley aplicable.<br><br>
            6. Sus derechos<br><br>
            Usted tiene ciertos derechos con respecto a su información personal de conformidad con el
            RGPD.
            Esto incluye el derecho de acceder, corregir, eliminar, limitar o oponerse al procesamiento de su
            información personal. Si desea ejercer alguno de estos derechos, por favor contáctenos utilizando
            la información de contacto que se proporciona al final de esta política de privacidad.<br><br>
            7. Seguridad de la información<br><br>
            Tomamos las medidas de seguridad adecuadas para proteger su información personal contra
            pérdida, uso indebido, acceso no autorizado, divulgación o alteración. Sin embargo, tenga en

            cuenta que ninguna medida de seguridad en Internet es totalmente segura.<br><br>
            8. Cambios a esta política de privacidad<br><br>
            Podemos actualizar esta política de privacidad ocasionalmente para reflejar cambios en nuestras
            prácticas de privacidad. Le recomendamos que revise esta política de privacidad periódicamente.
            La versión actualizada de la política de privacidad se publicará en nuestra aplicación y será
            efectiva a partir de la fecha de publicación.<br><br>
            9. Contacto<br><br>
            Si tiene alguna pregunta o inquietud acerca de esta política de privacidad o nuestras prácticas de
            privacidad, no dude en ponerse en contacto con nosotros a través de correo electrónico de
            contacto.<br>
            Al utilizar nuestra aplicación, usted acepta los términos de esta política de privacidad y consiente
            el procesamiento de su información personal de acuerdo con los fines y métodos descritos
            anteriormente.
            <br>
            <br>
            <br>
            Accesos Automáticos<br>
            Trea Condominios<br>
            San José, Costa Rica<br>
            accesosautomaticos.id@gmail.com<br>
          </div>

          <button (click)="viewPolitics()"mat-stroked-button class="login">
            Política de privacidad
          </button>
        
      </div> 

     
    </mat-card-content>
  </div>
<div>
