import { Injectable } from '@angular/core';
import { ROLE } from '../shared/ec-api/ec-api.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class RoleService {
	constructor(private http: HttpClient) {}

	checkLoginPermisions(roles: any): boolean {
		const rolesArray = ['SUPER_ADMIN', 'ADMIN', 'SECURITY'];
		return roles.find((role) => rolesArray.includes(role.key));
	}

	checkAdmin(roles: any): boolean {
		const rolesArray = ['SUPER_ADMIN', 'ADMIN'];
		return roles.find((role) => rolesArray.includes(role.key));
	}

	getRoles(): Observable<any> {
		return this.http.get<any>(`${ROLE}/?status=ACTIVE`);
	}
}
