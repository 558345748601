import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'auth' },

	// * Route without parameters
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
	},

	// * Route with parameters
	{
		path: 'auth/:token',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
	},

	{
		path: 'dashboard',
		component: DashboardComponent,
		children: [
			{
				path: 'user',
				loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
				outlet: 'menu',
			},
			{
				path: 'spaces',
				loadChildren: () => import('./spaces/spaces.module').then((m) => m.SpacesModule),
				outlet: 'menu',
			},
			{
				path: 'spot',
				loadChildren: () => import('./spot/spot.module').then((m) => m.SpotModule),
				outlet: 'menu',
			},
			{
				path: 'common-areas',
				loadChildren: () => import('./common-areas/common-areas.module').then((m) => m.CommonAreasModule),
				outlet: 'menu',
			},
			{
				path: 'reservations',
				loadChildren: () => import('./reservations/reservations.module').then((m) => m.ReservationsModule),
				outlet: 'menu',
			},
			{
				path: 'invitations',
				loadChildren: () => import('./authorizations/authorizations.module').then((m) => m.AuthorizationsModule),
				outlet: 'menu',
			},
			{
				path: 'request',
				loadChildren: () => import('./request/request.module').then((m) => m.RequestModule),
				outlet: 'menu',
			},
			{
				path: 'administrations',
				loadChildren: () => import('./administrations/administrations.module').then((m) => m.AdministrationsModule),
				outlet: 'menu',
			},
			{
				path: 'releases',
				loadChildren: () => import('./releases/releases.module').then((m) => m.ReleasesModule),
				outlet: 'menu',
			},
			{
				path: 'terminals',
				loadChildren: () => import('./terminals/terminals.module').then((m) => m.TerminalsModule),
				outlet: 'menu',
			},
			{
				path: 'record',
				loadChildren: () => import('./record/record.module').then((m) => m.RecordModule),
				outlet: 'menu',
			},
			{
				path: 'logs',
				loadChildren: () => import('./logs/logs.module').then((m) => m.LogsModule),
				outlet: 'menu',
			},
			{
				path: 'reports',
				loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
				outlet: 'menu',
			},
		],
	},
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],

	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}
