// import { environment } from '../../../environments/environment.prod';
// import { environment } from '../../../environments/environment.dev';
import { environment } from '../../../environments/environment';

const URL = environment.apiUrl;
// const URL_PROD = environment.apiUrlProd;

// OLD
export const AUTH_PATH = `${URL}/auth`;
export const AUTH_LOGIN_PATH = `${AUTH_PATH}/login`;

// NEW
export const BASE_URL = `${URL}/auth_login`;
export const AUTH_LOGIN = `${BASE_URL}/auth`;

export const FAKE_ROLES = `${BASE_URL}/user_role`;
export const FAKE_PERMITS = `${URL}/auth_login/permits`;

export const AUTH_FORGOT_PASS_PATH = `${AUTH_PATH}/forgot-password`;
export const AUTH_CHANGE_PATH = `${AUTH_PATH}/change`;
export const USER_ROLES = `${AUTH_PATH}/user-roles`;
export const USER = `${URL}/user`;
export const SPACES = `${URL}/spaces`;
export const SPOT = `${URL}/spot`;
export const COMMON_AREAS = `${URL}/common-areas`;
export const COMMON_AREAS_SCHEDULE = `${URL}/common-areas-schedule`;
export const ROLE = `${URL}/role`;
export const RESERVATION = `${URL}/reservation`;
export const AUTHORIZATIONS = `${URL}/authorizations`;
export const REQUEST = `${URL}/request`;
export const ADMINISTRATIONS = `${URL}/administrations`;
export const NOTIFICATION = `${URL}/notification`;
export const RELEASES = `${URL}/releases`;
export const TERMINALS = `${URL}/terminal`;
export const TAG = `${URL}/tag`;
export const CARD_ID = `${URL}/car-id`;
export const PERMITS = `${URL}/permits`;
export const NOTIFICATIONS = `${URL}/notification`;
export const PROCESS_SITES = `${SPACES}/config/process-site`;
