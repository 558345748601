import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from '../auth/auth.service';
import { NotificationEventService } from '../shared/notifications';
import { RoleService } from '../shared/role.service';
import { UserProfileComponent } from '../user/user-profile/user-profile.component';

@Component({
  selector: 'e-commerce-workspace-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {

  public open: boolean = false;
  public expand: boolean = false;
  public panelOpenState: boolean = false;
  public showImg: boolean = false;
  public user: any;
  userName: string;
  itsSuperAdmin: boolean = false;
  public userMenu: boolean = false;
  public spaceMenu: boolean = false;
  public reservationMenu: boolean = false;
  public reservationOfficerMenu: boolean = false;
  public invitationsMenu: boolean = false;
  public invitationsOfficerMenu: boolean = false;
  public administrationsMenu: boolean = false;
  public requestMenu: boolean = false;
  public releasesMenu: boolean = false;
  public showImage: boolean = true;
  public terminalsMenu:boolean = false;
  public logsMenu:boolean = false;
  public recordMenu:boolean = false;
  public reportsMenu: boolean = false;
  public permits: any[] = [];
  hideTitle: boolean = true;
  showFiller = false;
  showRouter = false;

  roles = {
    ADMIN: [
      'user-list',
      'spaces-list',
      'reservations-list',
      'invitations-list',
      'request-list',
      'administrations-list',
      'notifications-list',
      'releases-list',
      'record-list',
    ],
    SUPER_ADMIN: [
      'user-list',
      'spaces-list',
      'reservations-list',
      'invitations-list',
      'request-list',
      'administrations-list',
      'notifications-list',
      'releases-list',
      'terminals-list',
      'record-list'
    ],
    SECURITY: [
      'security-reservations-list',
      'security-invitations-list',
      'releases-list',
      'record-list',
      'logs-list'
    ]
  };

  constructor(
    private router: Router,
    private toaster: Toaster,
    private roleService: RoleService,
    private notificationService : NotificationEventService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private authService: AuthService,
    
  ) {
    this.matIconRegistry.addSvgIcon(
      `reservation`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/reservation.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `authorization`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/authorization.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `user`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/user-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `user-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/usuario.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `condominium`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/condominium.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `calendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/calendar.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `card`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/card.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `shield`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/shield.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `request`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/request.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `menu`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/menu.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `notification`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/notification.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `terminal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/terminal.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `record`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/scroll.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `notification_outline`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/error.svg`)
    );
  }
  

  verifyRoles (page: string)  {
  let result = false;
  if(this.user.role.find(role => role.key === 'SUPER_ADMIN')){
    this.user.role.forEach(userRole => {
      const cmpRole = this.roles[userRole.key];
  
      if (cmpRole.includes(page)) {
        result = true;
      }
  
    });

  }
  else{
    this.user.role.forEach(userRole => {
      const cmpRole = this.roles[userRole.key];
      if (cmpRole.includes(page) && this.permits.find(permit =>{ if(permit.name === page){return true}})) {
        result = true;
      }
  
    });
  }

      return result;
  }


  onExit() {
    
    const userStorage = localStorage.getItem("user");
    const userUuid  = JSON.parse(userStorage).uuid;
    const userToken = localStorage.getItem("notificationToken");
    this.notificationService.deleteToken(userUuid, userToken).subscribe();
    localStorage.removeItem('userName');
    localStorage.removeItem('user');
    this.router.navigate(['/auth']);
    this.toaster.open({
      text: 'Se cerro la sesion con Exito',
      caption: 'Excelente!',
      type: 'success',
    });
  }

  onUserProfile() {
    this.openDialog();
  }
  openDialog = (): void => {
    const dialogRef = this.dialog.open(UserProfileComponent, {
      width: '315px',
      height: 'auto',
      data: { user: this.user ? this.user : null },
      panelClass: 'custom-dialog-container' 
    });

    dialogRef.beforeClosed().subscribe((result) => {
      console.log(JSON.stringify(result));
    });
  };
  onUser(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['user'] } }]);
  }
  onSpaces(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['spaces'] } }]);
  }
  onReservations(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['reservations'] } }]);
  }

  onAuthorizations(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['invitations'] } }]);
  }

  onReservationsOfficer(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['reservations','officer'] } }]);
  }

  onAuthorizationsOfficer(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['invitations','officer'] } }]);
  }
  onRequest=(parametro)=>{
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['request'] } }]);
  }

  onAdministrations(parametro) {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['administrations'] } }]);
  }

  onReleases=(parametro)=>{
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['releases'] } }]);
  }

  onTerminals=(parametro)=>{
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['terminals'] } }]);
  }

  onRecord=(parametro)=>{
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['record'] } }]);
  }

  onLogs=(parametro)=>{
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['logs'] } }]);
  }

  onReports = (parametro) => {
    this.menuSelected(parametro);
    this.router.navigate(['dashboard', { outlets: { menu: ['reports'] } }]);
  }
  
  navIsOpen() {
    if (this.open === false && this.expand === false) {
      this.open = true;
      this.showImage = true;
      this.hideTitle = true;
    } else {
      if (this.open === true && this.expand === true) {
        this.open = false;
        this.expand = false;
        this.showImage = false;
        this.hideTitle = false;
      } else {
        if (this.open === true && this.expand === false) {
          this.open = false;
          this.showImage = false;
          this.hideTitle = false;
        }
      }
    }
  }
  
  isOpen() {
    if (this.open === false && this.expand === false) {
      this.expand = true;
      this.open = true;
      this.hideTitle = true;
    } else {
      if (this.open === true && this.expand === true) {
        this.expand = false;
        this.showImage = false;
        this.hideTitle = false;
      }
    }
  }

  menuSelected(parametro){
    this.userMenu = false;
    this.spaceMenu= false;
    this.reservationMenu = false;
    this.reservationOfficerMenu = false;
    this.invitationsMenu = false;
    this.invitationsOfficerMenu = false;
    this.administrationsMenu = false;
    this.requestMenu = false;
    this.releasesMenu = false;
    this.terminalsMenu=false;
    this.logsMenu=false;
    this.recordMenu=false;
    this.reportsMenu = false;

    this[parametro] = true;
  }
  
  showAlert(data) {
    const message=data.autogenerated?"El usuario "+ data.allowedName+" acaba de ingresar a la terminal "+ data.terminal:
    "El usuario "+ data.allowedName+" con cédula "+data.allowedId+" es un invitado de "+data.userName+
    ",acaba de ingresar a la terminal "+ data.terminal;
    this.toaster.open({
      text: message,
      caption: 'Importante! ',
      type: 'success',
      duration: 20000
    });
  };

  getLanguage(id){
    if(JSON.parse(localStorage.getItem('language'))){
      this.translateService.setDefaultLang(JSON.parse(localStorage.getItem('language')));
      this.translateService.addLangs(['english','español']);
    }else{
      this.authService.getLanguage(id).subscribe(
        (res) => {
        if(res.body.language){
   
          localStorage.setItem('language', JSON.stringify(res.body.language));
          this.translateService.setDefaultLang(res.body.language);
          this.translateService.addLangs(['english','español']);
        }
        },
        (error) => {
          this.toaster.open({
            text:  this.translateService.instant('general.ErrorLoadingLanguage'),
            caption: 'Error ',
            type: 'danger',
          });
        }
      );
    }

	}

  ngOnInit() {
    const user=JSON.parse(localStorage.getItem('user'));
   this.getLanguage(user.id);
    let element = document.getElementById('show-web');
    let compStyles = window.getComputedStyle(element);
    let hasClass = compStyles.getPropertyValue('display');
    if(hasClass === 'block'){
      this.showRouter = true;
    }else{
      this.showRouter = false;
    }
    if (localStorage.getItem('user') && localStorage.getItem('permits')) {
      this.userName = localStorage.getItem('userName');
      const userStorage = localStorage.getItem('user');
      const permitsStorage = localStorage.getItem('permits');
      if (userStorage && permitsStorage) {
        this.user = JSON.parse(userStorage);
        this.permits = JSON.parse(permitsStorage);
      }
      this.itsSuperAdmin = this.roleService.checkAdmin(this.user.role);
    } else {
     this.router.navigate(['/auth']);
    }
  }

}
