<h2 mat-dialog-title>{{'record.recordModal.continue' | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <p>
      <span> {{data.text}}</span>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()" class={{data.visibleContinue}}>{{'record.recordModal.continue' | translate}}</button>
  <button mat-button (click)="stop()" class={{data.visibleContinue}}>{{'record.recordModal.cancelDownload' | translate}}</button>
  
  <button mat-button mat-dialog-close  class={{data.visibleDownload}}>{{'record.recordModal.cancel' | translate}}</button>
  <a href={{data.url}} target ="_blank" class="button-item" (click)="closeDialog()" class={{data.visibleDownload}}>{{'record.recordModal.download' | translate}}</a>
  
</mat-dialog-actions>
