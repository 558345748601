<div class="main">
  <mat-card class="card">
    <div class="header">
      <img class="img" src="../../../assets/TREA APLICACION-03.png" />
      <a class="link" mat-button (click)="goBack()">
        <mat-icon (click)="goBack()">keyboard_backspace</mat-icon></a>
      <span class="label1">{{ 'forgotPassword.recover' | translate }}</span>
    
    </div>
    <div class="logo">
      <img src="../../../assets/trea logo blanco-01 (1).png">
    </div>
    <mat-card-content>
      <div class="card-form-container">
        <form class="card-form" [formGroup]="resetFormControl">
          <mat-form-field floatLabel="never" class="formField">
            <input
              matInput
              type="email"
              placeholder="{{ 'forgotPassword.email' | translate }}"
              formControlName="email"
              [errorStateMatcher]="matcher"
            />

            <mat-error *ngIf="resetFormControl.controls.email.hasError('email')">
            {{ 'forgotPassword.error.invalidEmail' | translate }}
            </mat-error>
            <mat-error *ngIf="resetFormControl.controls.email.hasError('required')">
            {{ 'forgotPassword.error.email' | translate }}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </mat-card-content>

    <mat-card-actions class="actions">
      <div class="reset-div">
        <button
          [disabled]="!resetFormControl.valid"
          mat-stroked-button
          class="reset"
          (click)="onSubmit()"
        >
        {{ 'forgotPassword.recover' | translate }}
        </button>
      </div>
    </mat-card-actions>
    <div>
      <img class="img2" src="../../../assets/TREA APLICACION-04.png" />
    </div>
  </mat-card>
</div>
