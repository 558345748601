<!DOCTYPE html>
<html>
	<head lang="en">
		<meta charset="UTF-8" />
		<title>Trea - Condominios</title>
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<link
			href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
			rel="stylesheet"
			integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
			crossorigin="anonymous"
		/>
		<script
			src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
			integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
			crossorigin="anonymous"
		></script>
		<meta name="theme-color" content="#e85e14" />
		<style type="text/css">
			:root {
				background-color: #e814e1;
			}
		</style>
	</head>

	<body>
		<div class="container d-flex justify-content-center align-items-center">
			<div class="row border rounded-5 p-3 shadow box-area main-container">
				<!-- ******** Language Flag ******** -->
				<!-- <div class="language-flag">
					<div class="language-flag-img">
						<small>
							<a>
								<img
									src="../../../assets/en.png"
									class="img-fluid flag-language"
									alt="Flag to change language to English"
								/>
							</a>
						</small>
					</div>
				</div> -->

				<!-- ******** Left Box ******** -->
				<div
					class="featured-image col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box left-img-container"
				></div>

				<!-- ******** Right Box ******** -->
				<div class="col-md-6 right-box">
					<div class="row align-items-center">
						<div class="header-text mb-4 right-box-head">
							<img class="right-box-logo" src="../../../assets/treaLogo.png" alt="Trea Logo" />
							<h1 class="text-center mb-4">Test Condominios</h1>
						</div>

						<form [formGroup]="loginFormControl" (ngSubmit)="handleLogin()">
							<!-- *** Email Input *** -->
							<div class="mb-3 form-group row">
								<mat-form-field appearance="outline" floatLabel="always">
									<input
										[class.is-invalid]="invalidEmail"
										autocomplete="off"
										matInput
										formControlName="email"
										placeholder="{{ placeholders.email }}"
									/>
									<mat-error *ngIf="invalidEmail">{{ 'login.emailError' | translate }}</mat-error>
									<!-- class="text-error d-flex justify-content-start align-items-start text-danger mt-2" -->
								</mat-form-field>
							</div>

							<!-- *** Password Input *** -->
							<div class="form-group row">
								<mat-form-field appearance="outline" floatLabel="always">
									<mat-label>{{ 'login.password' | translate }}</mat-label>
									<input
										[class.is-invalid]="invalidPassword"
										formControlName="password"
										matInput
										[type]="hidePassword ? 'text' : 'password'"
										placeholder="*************"
									/>
									<button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
										<mat-icon>{{ hidePassword ? 'visibility' : 'visibility_off' }}</mat-icon>
									</button>

									<mat-error *ngIf="invalidPassword">
										{{ 'login.passwordError' | translate }}:

										<mat-icon>
											<span
												data-toggle="tooltip"
												title="{{ 'login.passwordTooltip' | translate }}"
												style="cursor: pointer;"
												>ℹ️</span
											>
										</mat-icon>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- *** Forgot Password *** -->
							<div class="input-group mb-1 d-flex justify-content-between">
								<div class="form-check"></div>
								<div>
									<small>
										<a routerLink="/recovery-password">{{ 'login.recoveryPassword' | translate }}</a>
									</small>
								</div>
							</div>

							<!-- *** Button Login *** -->
							<div class="input-group mt-4 mb-4">
								<button class="button btn-lg w-100 fs-6">
									{{ 'login.enter' | translate }}
								</button>
							</div>
						</form>

						<div class="right-box-footer">
							<!-- *** Privacy Politics *** -->
							<div class="input-group mb-2 mt-2 d-flex justify-content-center align-items-center">
								<small class="text-center privacy-politics">
									{{ 'login.privacy' | translate }}
									<a href="https://trea.cr/condominios/privacy-policy/" target="_blank">
										{{ 'login.policy' | translate }}
									</a>
								</small>
								<a href="#" target="_blank">🔺DEVELOP🔻</a>
							</div>

							<!-- *** Environment Deployed *** -->
							<div
								*ngIf="isDevEnvironment"
								class="input-group mb-2 mt-4 d-flex justify-content-center align-items-center"
							>
								<small class="text-center environment-deployed">
									{{ 'login.environment' | translate }}:
									<a href="https://thumbs.dreamstime.com/b/computer-repair-concept-3382437.jpg">DEVELOP 👨🏽‍💻</a>
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</body>
</html>
