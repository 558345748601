import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { EcErrorStateMatcher } from '../../shared/error-state-matcher/error-state-matcher';
import { LoadingIndicatorService } from '../../shared/loading-indicator/loading-indicator.service';
import { UserEntity } from '../../shared/models/user-model.module';
import { AuthService } from '../auth.service';

@Component({
	selector: 'e-commerce-workspace-change',
	templateUrl: './change.component.html',
	styleUrls: ['./change.component.css'],
})
export class ChangeComponent implements OnInit {
	changeFormControl = new FormGroup({
		newPassword: new FormControl(null, [
			Validators.required,
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
		confirmNewPassword: new FormControl(null, [
			Validators.required,
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
	});
	public hidePassword = true;
	public hideNewPassword = true;
	public visible = true;
	matcher = new EcErrorStateMatcher();
	public user: UserEntity;

	constructor(
		private router: Router,
		private loadingIndicator: LoadingIndicatorService,
		private authService: AuthService,
		private toaster: Toaster,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.user = this.authService.user;
	}

	onSubmit() {
		this.loadingIndicator.show();
		this.user.password = this.changeFormControl.controls.newPassword.value;
		this.authService.changePass(this.user).subscribe(this.changeSuccess, this.changeFailure);
	}

	changeSuccess = (res: any) => {
		this.goToLogin();
	};

	changeFailure = (res: any) => {
		this.toaster.open({
			text: this.translate.instant('change.toasters.changeFailure.message'),
			caption: this.translate.instant('change.toasters.changeFailure.title'),
			type: 'danger',
		});
		this.loadingIndicator.hide();
	};

	goToLogin() {
		this.router.navigate(['/auth']);
		this.toaster.open({
			text: this.translate.instant('change.toasters.changeSuccess.message'),
			caption: this.translate.instant('change.toasters.changeSuccess.title'),
			type: 'success',
		});
		this.loadingIndicator.hide();
	}
	goBack() {
		this.router.navigate(['/auth']);
	}
}
