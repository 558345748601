import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { EcErrorStateMatcher } from '../../shared/error-state-matcher/error-state-matcher';
import { UserEntity } from '../../shared/models/user-model.module';
import { AuthService } from '../auth.service';

@Component({
  selector: 'e-commerce-workspace-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  resetFormControl = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  matcher = new EcErrorStateMatcher();

  constructor(
    private router: Router,
    private authService: AuthService,
    private toaster: Toaster,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    const user: UserEntity = {
      email: this.resetFormControl.controls.email.value,
    };
    this.authService
      .forgotPass(user)
      .subscribe(this.resetSuccess, this.resetFailure);

    //this.router.navigate(['/change'])
  }
  resetSuccess = (res: any) => {
    this.toaster.open({
      text: this.translate.instant('forgotPassword.toasters.resetSuccess.message'),
			caption: this.translate.instant('forgotPassword.toasters.resetSuccess.title'),
      type: 'warning',
    });
    this.goBack();
  };
  resetFailure = (res: any) => {
    this.toaster.open({
      text: this.translate.instant('forgotPassword.toasters.resetFailure.message'),
			caption: this.translate.instant('forgotPassword.toasters.resetFailure.title'),
      type: 'danger',
    });
  };

  goBack() {
    this.router.navigate(['/auth']);
  }
}
