<div class="cancel">
	<button disableRipple mat-button color="primary" (click)="onClose()">
		<mat-icon svgIcon="cancel"></mat-icon>
	</button>
	<div>
		<button *ngIf="this.stepper.selectedIndex !== 0" mat-button>
			<mat-icon (click)="changePassword = !changePassword" (click)="move(0)" color="primary" matSuffix
				>arrow_back</mat-icon
			>
		</button>
	</div>
</div>

<div class="main">
	<div class="card">
		<div
			class="ripple-container mat-elevation-z4"
			matRipple
			(click)="fileInput.click()"
			[matRippleCentered]="true"
			[matRippleDisabled]="false"
			[matRippleUnbounded]="true"
			[matRippleRadius]="150"
		>
			<img
				*ngIf="profilePhoto !== ''"
				class="ripple-container"
				[src]="this.userPicture.url ? this.userPicture.url : this.userPicture.picture"
			/>

			<mat-icon
				class="defaultIcon"
				*ngIf="profilePhoto === null || profilePhoto === ''"
				matSuffix
				svgIcon="user"
			></mat-icon>
		</div>
		<input style="display: none;" type="file" (change)="onFileSelected($event)" #fileInput />

		<mat-horizontal-stepper [linear]="isLinear" #stepper>
			<mat-step [stepControl]="firstFormGroup">
				<form [formGroup]="firstFormGroup">
					<div class="main">
						<div>
							<form class="form" [formGroup]="userFormControl">
								<div class="controls">
									<mat-form-field class="formField">
										<input
											matInput
											type="text"
											placeholder="{{'userProfile.name' | translate}}"
											formControlName="name"
											[errorStateMatcher]="matcher"
										/>

										<mat-error *ngIf="userFormControl.controls.name.hasError('required')">
											{{'userProfile.error.name' | translate}}
											<strong>{{'userProfile.error.required' | translate}}</strong>
										</mat-error>
									</mat-form-field>

									<mat-form-field class="formField">
										<input
											matInput
											type="text"
											placeholder="{{'userProfile.lastName' | translate}}"
											formControlName="lastName"
											[errorStateMatcher]="matcher"
										/>

										<mat-error *ngIf="userFormControl.controls.lastName.hasError('required')">
											{{'userProfile.error.lastName' | translate}}
											<strong>{{'userProfile.error.required' | translate}}</strong>
										</mat-error>
									</mat-form-field>

									<mat-form-field class="formField">
										<input
											matInput
											type="text"
											placeholder="{{'userProfile.surname' | translate}}"
											formControlName="surName"
											[errorStateMatcher]="matcher"
										/>

										<mat-error *ngIf="userFormControl.controls.surName.hasError('required')">
											{{'userProfile.error.surname' | translate}}
											<strong>{{'userProfile.error.required' | translate}}</strong>
										</mat-error>
									</mat-form-field>

									<mat-form-field class="formField">
										<input
											matInput
											type="text"
											placeholder="{{'userProfile.phone' | translate}}"
											formControlName="phone"
											[errorStateMatcher]="matcher"
											(keyup)="validateNumberTel($event)"
										/>

										<mat-error *ngIf="userFormControl.controls.phone.hasError('required')">
											{{'userProfile.error.phone' | translate}}
											<strong>{{'userProfile.error.required' | translate}}</strong>
										</mat-error>
									</mat-form-field>
								</div>

								<div class="controls">
									<mat-form-field class="formField">
										<input
											matInput
											type="text"
											placeholder="{{'userProfile.mobile' | translate}}"
											formControlName="mobile"
											[errorStateMatcher]="matcher"
											(keyup)="validateNumber($event)"
										/>

										<mat-error *ngIf="userFormControl.controls.mobile.hasError('required')">
											{{'userProfile.error.mobile' | translate}}
											<strong>{{'userProfile.error.required' | translate}}</strong>
										</mat-error>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{'userProfile.language' | translate}}</mat-label>
										<select matNativeControl required formControlName="language">
											<option value="español">		{{'userProfile.spanish' | translate}}</option>
											<option value="english">{{'userProfile.english' | translate}}</option>
										</select>
									</mat-form-field>
								
									<mat-form-field class="formField">
										<input
											matInput
											type="text"
											placeholder="{{'userProfile.email' | translate}}"
											formControlName="email"
											[errorStateMatcher]="matcher"
										/>
										<mat-error *ngIf="userFormControl.controls.email.hasError('email')">
											{{'userProfile.error.invalidEmail' | translate}}
										</mat-error>
										<mat-error *ngIf="userFormControl.controls.email.hasError('required')">
											{{'userProfile.error.email' | translate}}
											<strong>{{'userProfile.error.email' | translate}}</strong>
										</mat-error>
									</mat-form-field>

									<mat-form-field class="formField">
										<input
											[readonly]="user"
											matInput
											[type]="hidePassword ? 'password' : 'text'"
											placeholder="{{'userProfile.password' | translate}}"
											formControlName="password"
											[errorStateMatcher]="matcher"
										/>
										<button
											type="button"
											mat-icon-button
											matSuffix
											matStepperNext
											(click)="changePassword = !changePassword"
										>
											<mat-icon class="passIcon" color="primary">arrow_forward</mat-icon>
										</button>
										<button
											type="button"
											mat-icon-button
											matSuffix
											(click)="hidePassword = !hidePassword"
											[attr.aria-label]="'Hide password'"
											[attr.aria-pressed]="hidePassword"
										>
											<mat-icon color="primary">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
										</button>
										<mat-error *ngIf="userFormControl.controls.password.hasError('required')">
											{{'userProfile.error.password' | translate}}
											<strong>{{'userProfile.error.required' | translate}}</strong>
										</mat-error>
										<mat-error *ngIf="userFormControl.controls.password.hasError('pattern')">
											{{'userProfile.error.invalidPassword1st' | translate}}
											<strong>{{'userProfile.error.invalidPassword2nd' | translate}}</strong>
										</mat-error>
									</mat-form-field>
								</div>
							</form>
						</div>
					</div>
				</form>


		


				
			</mat-step>
			<mat-step [stepControl]="secondFormGroup">
				<form [formGroup]="secondFormGroup">
					<div>
						<form class="form" [formGroup]="userFormControl2">
							<div class="controls">
								<mat-form-field class="formField">
									<input
										(change)="onConfirm()"
										matInput
										[type]="hideNewPassword ? 'password' : 'text'"
										placeholder="{{'userProfile.newPassword' | translate}}"
										formControlName="newPassword"
										[errorStateMatcher]="matcher"
									/>
									<button
										type="button"
										mat-icon-button
										matSuffix
										(click)="hideNewPassword = !hideNewPassword"
										[attr.aria-label]="'Hide new password'"
										[attr.aria-pressed]="hideNewPassword"
									>
										<mat-icon color="primary">{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
									</button>
									<mat-error *ngIf="userFormControl2.controls.newPassword.hasError('required')">
										{{'userProfile.error.password' | translate}}
										<strong>{{'userProfile.error.required' | translate}}</strong>
									</mat-error>
									<mat-error *ngIf="userFormControl2.controls.newPassword.hasError('pattern')">
										{{'userProfile.error.invalidPassword1st' | translate}}
										<strong>{{'userProfile.error.invalidPassword2nd' | translate}}</strong>
									</mat-error>
								</mat-form-field>

								<mat-form-field class="formField">
									<input
										matInput
										ngValidateEqual="newPassword"
										[type]="hideConfirmPassword ? 'password' : 'text'"
										placeholder="{{'userProfile.confirmNewPassword' | translate}}"
										formControlName="confirmNewPassword"
										[errorStateMatcher]="matcher"
									/>
									<button
										type="button"
										mat-icon-button
										matSuffix
										(click)="hideConfirmPassword = !hideConfirmPassword"
										[attr.aria-label]="'Hide Confirm password'"
										[attr.aria-pressed]="hideConfirmPassword"
									>
										<mat-icon color="primary">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
									</button>
									<mat-error *ngIf="userFormControl2.controls.newPassword.hasError('required')">
										{{'userProfile.error.password' | translate}}
										<strong>{{'userProfile.error.required' | translate}}</strong>
									</mat-error>
									<mat-error *ngIf="userFormControl2.controls.confirmNewPassword.hasError('pattern')">
										{{'userProfile.error.invalidPassword1st' | translate}}
										<strong>{{'userProfile.error.invalidPassword2nd' | translate}}</strong>
									</mat-error>
									<mat-error *ngIf="userFormControl2.controls.confirmNewPassword.hasError('notEqual')">
										<strong>{{'userProfile.error.confirmNewPassword' | translate}}</strong>
									</mat-error>
								</mat-form-field>
							</div>
						</form>
					</div>
				</form>
				<br /><br />
				<div class="change-div">
					<button
						matStepperPrevious
						(click)="onConfirm()"
						type="button"
						[disabled]="!userFormControl2.valid"
						mat-stroked-button
						class="change"
					>
						<div>{{'userProfile.save' | translate}}</div>
					</button>
				</div>
			</mat-step>
		</mat-horizontal-stepper>
		<div class="actions">
			<div *ngIf="!changePassword" class="change-div">
				<button (click)="onSubmit()"  mat-stroked-button class="change">
					<div>{{'userProfile.update' | translate}}</div>
				</button>
			</div>
		</div>
	</div>
</div>
<ngx-spinner></ngx-spinner>
