import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { EcErrorStateMatcher } from '../../shared/error-state-matcher/error-state-matcher';
import { LoadingIndicatorService } from '../../shared/loading-indicator/loading-indicator.service';
import { UserEntity } from '../../shared/models/user-model.module';
import { RoleService } from '../../shared/role.service';
import { UserService } from '../../user/user.service';
import { AuthService } from '../auth.service';
@Component({
	selector: 'e-commerce-workspace-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent {
	loginFormControl = new FormGroup({
		email: new FormControl(null, [Validators.required, Validators.email]),
		password: new FormControl(null, [
			Validators.required,
			Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?*+¿,.&])[A-Za-z\d@!;$%*=?¿.,&]{8,50}$/),
		]),
	});
	public hidePassword = true;
	public visible = true;
	matcher = new EcErrorStateMatcher();
	public user: UserEntity;
	public isDevEnvironment: boolean = false;
	public currentDomain: string;
	public token: string;
	public currentPath: URL;

	constructor(
		private router: Router,
		private loadingIndicator: LoadingIndicatorService,
		private authService: AuthService,
		private userService: UserService,
		private toaster: Toaster,
		private roleService: RoleService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private translate: TranslateService
	) {
		this.matIconRegistry.addSvgIcon(
			`password`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/padlock.svg`)
		);
		this.matIconRegistry.addSvgIcon(
			`user`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/userS.svg`)
		);
	}

	ngOnInit(): void {
		localStorage.setItem('language', JSON.stringify('español'));
		this.isDevEnvironment = window.location.href.indexOf('https://dev') === 0;

		this.currentPath = new URL(window.location.href);
		this.token = this.currentPath?.searchParams?.get('token');
		this.currentDomain = this.currentPath.hostname;
		this.token && this.authService.login3(this.token).subscribe(this.loginSuccess, this.loginFailure);
	}

	placeholders = {
		email: this.translate.instant('login.emailPlaceholder'),
	};

	get invalidEmail() {
		return this.loginFormControl.get('email')?.invalid && this.loginFormControl.get('email')?.touched;
	}

	get invalidPassword() {
		return this.loginFormControl.get('password')?.invalid && this.loginFormControl.get('password')?.touched;
	}

	togglePasswordVisibility() {
		this.hidePassword = !this.hidePassword;
	}

	handleLogin() {
		this.loadingIndicator.show();
		if (this.loginFormControl.invalid) {
			return Object.values(this.loginFormControl.controls).forEach((control) => {
				control.markAsTouched();
			});
		}

		this.user = {
			email: this.loginFormControl.controls.email.value,
			password: this.loginFormControl.controls.password.value,
		};

		this.authService.login(this.user).subscribe(this.loginSuccess, this.loginFailure);
	}

	loginSuccess = (res: any) => {
		this.authService.getUserRole(res).subscribe((roles) => {
			this.loadingIndicator.hide();
			if (this.roleService.checkLoginPermisions(roles['body'])) {
				res.role = roles['body'];
				localStorage.setItem('user', JSON.stringify(res));
				localStorage.setItem('language', JSON.stringify(res.language));
				localStorage.setItem('userName', this.loginFormControl.value.email);
				localStorage.setItem('screen', '');
				const token = localStorage.getItem('notificationToken');
				this.userService.safeTokenUser({ uuid: res.uuid, id: res.id, token }).subscribe();
				this.userService.getPermitsByUser(res.uuid).subscribe((result) => {
					localStorage.setItem('permits', JSON.stringify(result.body));
					this.redirectByPermissions(res.processSite);
				});
				this.getLanguage(res.id);
			} else {
				this.toaster.open({
					text: this.translate.instant('login.toasters.userWithoutPermits.message'),
					caption: this.translate.instant('login.toasters.userWithoutPermits.title'),
					type: 'danger',
				});
			}
		});
	};

	loginFailure = (res: any) => {
		this.loadingIndicator.hide();
		switch (res.status) {
			case 405:
				this.authService.user = this.user;
				this.router.navigate(['/change']);
				break;
			case 404:
				this.toaster.open({
					text: this.translate.instant('login.toasters.inactiveUser.message'),
					caption: this.translate.instant('login.toasters.inactiveUser.title'),
					type: 'info',
				});
				break;

			default:
				this.toaster.open({
					text: this.translate.instant('login.toasters.passwordOrEmailIncorrect.message'),
					caption: this.translate.instant('login.toasters.passwordOrEmailIncorrect.title'),
					type: 'danger',
				});
				break;
		}
	};

	getLanguage(id) {
		if (JSON.parse(localStorage.getItem('language'))) {
			this.translate.addLangs(['english', 'español']);
			this.translate.setDefaultLang(JSON.parse(localStorage.getItem('language')));
		} else {
			this.authService.getLanguage(id).subscribe(
				(res) => {
					if (res.body.language) {
						this.translate.addLangs(['english', 'español']);
						this.translate.setDefaultLang(res.body.language);
					}
				},
				(error) => {
					this.translate.addLangs(['english', 'español']);
					this.translate.setDefaultLang('español');
					this.toaster.open({
						text: this.translate.instant('general.ErrorLoadingLanguage'),
						caption: 'Error ',
						type: 'danger',
					});
				}
			);
		}
	}

	redirectByPermissions(userProcessSite: string) {
		this.loadingIndicator.hide();
		const currentProcessSite = new URL(userProcessSite);
		const currentProcessDomainSite = currentProcessSite.hostname;

		currentProcessDomainSite !== this.currentDomain
			? (window.location.href = userProcessSite)
			: this.router.navigate(['/dashboard']);
	}

	goToDashboard() {
		this.loadingIndicator.hide();
		this.router.navigate(['/dashboard']);
	}
}
