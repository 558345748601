import { Injectable } from '@angular/core';
import { UserEntity } from '../shared/models/user-model.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	AUTH_LOGIN_PATH,
	AUTH_FORGOT_PASS_PATH,
	AUTH_CHANGE_PATH,
	USER_ROLES,
	USER,
	AUTH_LOGIN,
	FAKE_ROLES,
} from '../shared/ec-api/ec-api.module';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient) {}
	public user: UserEntity;
	httpOptions = {
		headers: new HttpHeaders({
			Authorization: '5TyX0s9ktFAvBFFMWWrxI45qGT2O1fnZ',
		}),
	};

	// OLD
	public login = (data: UserEntity): Observable<UserEntity> => {
		return this.http.post<UserEntity>(`${AUTH_LOGIN_PATH}`, data);
	};

	// NEW WITHOUT PARAMS
	public login2 = (data: UserEntity): Observable<UserEntity> => {
		return this.http.post<UserEntity>(`${AUTH_LOGIN}`, data, this.httpOptions);
	};

	// NEW WITH PARAMS
	public login3 = (TOKEN: any): Observable<any> => {
		const myURL = `${AUTH_LOGIN_PATH}?token=${TOKEN}`;
		return this.http.post<UserEntity>(myURL, {});
	};

	public forgotPass = (data: UserEntity): Observable<UserEntity> => {
		return this.http.post<UserEntity>(`${AUTH_FORGOT_PASS_PATH}`, data);
	};
	public changePass = (data: UserEntity): Observable<UserEntity> => {
		return this.http.post<UserEntity>(`${AUTH_CHANGE_PATH}`, data);
	};

	// OLD
	public getUserRole = (data: UserEntity) => {
		return this.http.post<UserEntity>(`${USER_ROLES}`, data);
	};

	// NEW
	public getUserRole2 = () => {
		return this.http.get<UserEntity>(`${FAKE_ROLES}`, this.httpOptions);
	};

	getLanguage(id: number): Observable<any> {
		return this.http.get<any>(`${USER}/userLenguage/${id}`);
	}

	updateLanguage = (authorizationForm: FormData): Observable<any> => {
		return this.http.put<any>(`${USER}/language`, authorizationForm);
	};
}
